/*
 * Copyright 2022 Nordeck IT + Consulting GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  PowerLevelsStateEvent,
  STATE_EVENT_POWER_LEVELS,
  StateEvent,
  isValidPowerLevelStateEvent,
} from '@prenigma-widget-toolkit/api';
import { first, isError } from 'lodash';
import { EventDirection, WidgetEventCapability } from 'prenigma-widget-api';
import { ThunkExtraArgument, baseApi } from '../store';

/**
 * Endpoints to receive the current room power levels and update it.
 *
 * @remarks This api extends the {@link baseApi} and should
 *          not be registered at the store.
 */
export const powerLevelsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /** Receive the room power levels of the current room */
    getPowerLevels: builder.query<
      { content: PowerLevelsStateEvent | undefined },
      void
    >({
      // do the initial loading
      async queryFn(_, { extra }) {
        const { widgetApi } = extra as ThunkExtraArgument;

        try {
          const events = await widgetApi.receiveStateEvents(
            STATE_EVENT_POWER_LEVELS,
          );

          const event = first(events.filter(isValidPowerLevelStateEvent));

          return { data: { content: event?.content } };
        } catch (e) {
          return {
            error: {
              name: 'LoadFailed',
              message: `Could not load room name: ${
                isError(e) ? e.message : JSON.stringify(e)
              }`,
            },
          };
        }
      },

      // observe the room and apply updates to the redux store.
      // see also https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#using-the-oncacheentryadded-lifecycle
      async onCacheEntryAdded(
        _,
        { cacheDataLoaded, cacheEntryRemoved, extra, updateCachedData },
      ) {
        const { widgetApi } = extra as ThunkExtraArgument;

        // wait until first data is cached
        await cacheDataLoaded;

        const subscription = widgetApi
          .observeStateEvents(STATE_EVENT_POWER_LEVELS)
          .subscribe(async (event) => {
            // update the cached data if the event changes in the room
            if (isValidPowerLevelStateEvent(event)) {
              updateCachedData(() => ({ content: event.content }));
            } else {
              updateCachedData(() => ({ content: undefined }));
            }
          });

        // wait until subscription is cancelled
        await cacheEntryRemoved;

        subscription.unsubscribe();
      },
    }),

    /** Update the name of the current room */
    updatePowerLevels: builder.mutation<
      StateEvent<PowerLevelsStateEvent>,
      PowerLevelsStateEvent
    >({
      // Optimistic update the local cache to instantly see the updated room name.
      // Undo the change if the query fails.
      async onQueryStarted(content, { dispatch, queryFulfilled }) {
        const { undo } = dispatch(
          powerLevelsApi.util.updateQueryData(
            'getPowerLevels',
            undefined,
            (draft) => {
              if (draft.content) {
                draft.content = content;
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          undo();
        }
      },

      async queryFn(content, { extra }) {
        const { widgetApi } = extra as ThunkExtraArgument;

        try {
          await widgetApi.requestCapabilities([
            WidgetEventCapability.forStateEvent(
              EventDirection.Send,
              STATE_EVENT_POWER_LEVELS,
            ),
          ]);

          const newEvent = await widgetApi.sendStateEvent(
            STATE_EVENT_POWER_LEVELS,
            content,
          );

          return { data: newEvent };
        } catch (e) {
          return {
            error: {
              name: 'UpdateFailed',
              message: `Could not update the power levels: ${
                isError(e) ? e.message : e
              }`,
            },
          };
        }
      },
    }),
  }),
});

// consume the store using the hooks generated by RTK Query
export const { useGetPowerLevelsQuery, useUpdatePowerLevelsMutation } =
  powerLevelsApi;
